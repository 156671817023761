import iron1 from './images/iron/iron1.jpeg'
import iron2 from './images/iron/iron2.jpeg'
import iron3 from './images/iron/iron3.jpeg'
import iron4 from './images/iron/iron4.jpeg'
import perfection from './images/iron/perfection.jpeg'
import steelmaker from './images/iron/steelmaker.jpeg'
const IronData = [{
    id: '1',
    name: 'Flat steel',
    detils: 'we offer more type of iron ',
    image: iron1,
}
,
{
    id: '2',
    name: 'Rebar',
    detils: 'we offer more type of iron ',
    image: iron2,
}
,
{
    id: '3',
    name: 'Steel iron',
    detils: 'we offer more type of iron ',
    image: iron3,
}
,
{
    id: '4',
    name: 'Rebar',
    detils: 'we offer more type of iron ',
    image: iron4,
}
,
{
    id: '5',
    name: 'Perfection in steel',
    detils: 'we offer more type of iron ',
    image: perfection,
}
,
{
    id: '6',
    name: 'Steel maker',
    detils: 'we offer more type of iron ',
    image: steelmaker,
}


]

export default IronData;