
import clinker425R from './images/clinker/clinker42.5R.jpeg'
import clinker325N from './images/clinker/clinker32.5N.jpeg'
const ClinkerData = [
    {
        id: 1,
        name: 'Clinker 42.5 R',
        detils: 'we offer many type of clinker',
        image: clinker425R,
    }
    ,
    {
        id: 2,
        name: 'Clinker 32.5 N',
        detils: 'we offer many type of clinker',
        image: clinker325N,
    }
]
export default ClinkerData