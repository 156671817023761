import fur1 from './images/furniture/fur1.jpeg'
import fur2 from './images/furniture/fur2.jpeg'
import fur3 from './images/furniture/fur3.jpeg'
import fur4 from './images/furniture/fur4.jpeg'
import fur5 from './images/furniture/fur5.jpeg'
import fur6 from './images/furniture/fur6.jpeg'
import fur7 from './images/furniture/fur7.jpeg'
import fur8 from './images/furniture/fur8.jpeg'
import fur9 from './images/furniture/fur9.jpeg'
import fur10 from './images/furniture/fur10.jpeg'
import fur11 from './images/furniture/fur11.jpeg'
const FurnitureData = [
    {
        id: '1',
        name: 'Furniture',
        detils: 'We offer  all types of furniture with the best quality',
        image: fur1,
    }
    ,
    {
        id: '2',
        name: 'Furniture',
        detils: 'We offer  all types of furniture with the best quality',
        image: fur2,
    }
    ,
    {
        id: '3',
        name: 'Furniture',
        detils: 'We offer  all types of furniture with the best quality',
        image: fur3,
    }
    ,
    {
        id: '4',
        name: 'Furniture',
        detils: 'We offer  all types of furniture with the best quality',
        image: fur4,
    }
    ,
    {
        id: '5',
        name: 'Furniture',
        detils: 'We offer  all types of furniture with the best quality',
        image: fur5,
    }
    ,
    {
        id: '6',
        name: 'Furniture',
        detils: 'We offer  all types of furniture with the best quality',
        image: fur6,
    }
    ,
    {
        id: '7',
        name: 'Furniture',
        detils: 'We offer  all types of furniture with the best quality',
        image: fur7,
    }
    ,
    {
        id: '8',
        name: 'Furniture',
        detils: 'We offer  all types of furniture with the best quality',
        image: fur8,
    }
    ,
    {
        id: '9',
        name: 'Furniture',
        detils: 'We offer  all types of furniture with the best quality',
        image: fur9,
    }
    ,
    {
        id: '10',
        name: 'Furniture',
        detils: 'We offer  all types of furniture with the best quality',
        image: fur10,
    }
    ,
    {
        id: '11',
        name: 'Furniture',
        detils: 'We offer  all types of furniture with the best quality',
        image: fur11,
    }
]

export default FurnitureData;